// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/TreatmentPlans/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_TreatmentPlans_Content(
  $treatment_plan_id: uuid!
  $treatment_id: uuid!
  $is_financials_managed_externally: Boolean!
  $with_financial_externally: Boolean!
) {
  treatments_accept_treatment(treatment_plan_id: $treatment_plan_id) {
    id
  }
  update_vaxiom_txs(
    where: { _id: { _eq: $treatment_id } }
    _set: {
      is_financials_managed_externally: $is_financials_managed_externally
    }
  ) @include(if: $with_financial_externally) {
    affected_rows
  }
}

`