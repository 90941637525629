// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/AppointmentOverlay/Content/Form/Content/AppointmentSelect/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_AppointmentOverlay_Content_Form_Content_AppointmentSelect($treatment_id: uuid!, $location_id: uuid!) {
  treatments_timeline(
    args: { _treatment_id: $treatment_id }
    where: { type: { _eq: "appointment" } }
  ) {
    id
    appointment {
      id
      _id
      interval_to_next
      booking {
        id
        _id
        state
        start_time
        local_start_date
        local_start_time
        duration
        chair {
          id
          _id
        }
      }
      type {
        id
        _id
        display_name
        display_color_id
        appointment_templates(
          where: {
            organization: { _id: { _eq: $location_id } }
            deleted: { _eq: false }
          }
        ) {
          id
          duration
        }
      }
      tx {
        id
        _id
      }
    }
  }
}

`