import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let connection_app_tags = useDataValue({
    context: 'connection_definition',
    path: 'tags',
    viewPath: props.viewPath,
  })
  let stepsData = useDataValue({
    context: '_steps',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    let automations = stepsData.apps_automations.automations
      // only keep the missing automations
      .filter(automation => !automation.is_created)
      .map(automation => ({
        ...automation,
        step_type: 'automation',
        status: 'pending',
      }))

    let properties = stepsData.apps_connections_by_pk.app.properties?.map(
      property => ({
        key: property.key,
        name: property.name,
        required: property.required,
        step_type: 'preferences',
        status: 'pending',
        value: null,
        ...stepsData.apps_connections_by_pk.resource_properties[0],
      })
    )
    let steps = [...properties, ...automations]

    return {
      steps: steps.map((step, index) => ({ ...step, index })),
      current_index: 0,
    }
  }, [connection_app_tags])
}
