import { useDataChange, useDataValue } from 'Simple/Data.js'
import { dateShortOut } from 'Data/format.js'
import { isWeekly } from 'Data/payment-plan.js'
import { date } from 'Data/validate.js'

export function useOnBlur(props) {
  let value = useDataValue({
    context: 'payor',
    path: 'first_due_date',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onBlur() {
    // don't update parent context when the date is invalid
    if (!date(value)) {
      return
    }

    change(next => {
      let payor = next.payors[index]
      payor.first_due_date = value

      if (isWeekly(payor.installment_interval)) {
        let day_of_the_week = new Date(value).getDay()
        // expecting to be 1-7, while JS returns 0-6 (starting with Sunday)
        payor.first_installment_date =
          day_of_the_week === 0 ? 7 : day_of_the_week
      } else {
        payor.first_installment_date = new Date(value).getDate()
      }
    })
  }
}

export function formatOutDate(value) {
  let length = value.split('/')?.[2].length
  let formatted = dateShortOut(value)
  let isValid = date(formatted)
  return length !== 4 || !isValid ? value : formatted
}
