import addDays from 'date-fns/addDays/index.js'
import format from 'date-fns/format/index.js'
import differenceInDays from 'date-fns/differenceInDays/index.js'

import parseISO from 'date-fns/parseISO/index.js'

export function getDescription(value) {
  let expiring = value.connections.filter(connection => {
    let expiresDate = addDays(
      parseISO(connection.updated_at),
      value.connection.expiresInDays
    )
    return differenceInDays(expiresDate, new Date()) <= 10
  })[0]

  let expiresDate = addDays(
    parseISO(expiring.updated_at),
    value.connection.expiresInDays
  )

  return `Your connection to ${value.app_name} will reset in ${differenceInDays(
    expiresDate,
    new Date()
  )} days on ${format(expiresDate, 'MMM d, yyyy, h:mm aaa')}.`
}
