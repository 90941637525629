import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { timeToMinutes } from 'Data/format.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })

  let appointment_id = useDataValue({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
    path: 'appointment_id',
  })

  let is_external_any = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'is_external_any',
  })

  return async function onChange(next) {
    if (is_external_any) {
      /**
       * Try finding the appointment from the list of appointments that we have fetched in the useDataTransform and set the approximate date
       * This is helpful in cases where the appointment overlay is being opened externally. But we also need to be cautioned against appointment
       * overlay being opened due to editing / rescheduling an already scheduled appointment. To not display the blue bar in those cases, we have the
       * condition below `appointment.approximateDate !== null`. For all appointments that are already scheduled, the `calculateApproximateDate` in
       * `useDataTransform` returns null. And we filter them out here using the null check
       */

      let appointment = next?.find(v => v._id === appointment_id)
      if (appointment && appointment.approximateDate !== null) {
        submit({
          type: 'setExistingAppointment',
          approximateDate: appointment.approximateDate,
        })
      }
      return
    }

    let preferred = next?.filter(
      v => v.booking?.length === 0 && v.id !== 'new'
    )?.[0]

    if (preferred) {
      /**
       * If the appointment is an internal one, that is they have selected it
       * after searching for the patient, then the approximate date is set
       * while setting the rest of the details of the selected appointment
       */
      submit({
        type: 'setExistingAppointment',
        id: preferred._id,
        type_id: preferred.type._id,
        treatment_id: preferred.tx._id,
        duration: preferred.type.appointment_templates?.[0]?.duration ?? null,
        approximateDate: preferred.approximateDate,
        ...(preferred.booking.length > 0
          ? {
              preselect_scheduling_slot: {
                start_min: timeToMinutes(preferred.booking[0].local_start_time),
                date: preferred.booking[0].local_start_date,
                chair_id: preferred.booking[0].chair._id,
              },
            }
          : {}),
      })
    }
  }
}
