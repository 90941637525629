import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation, useClient } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import mutationAppsConnectionResourceEnable from './mutation-apps-connection-resource-enable.graphql.js'
import mutationAppsConnectionResourceDisable from './mutation-apps-connection-resource-disable.graphql.js'
import query from './query.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let [, executeAppsConnectionResourceEnableMutation] = useMutation(
    mutationAppsConnectionResourceEnable
  )
  let [, executeAppsConnectionResourceDisableMutation] = useMutation(
    mutationAppsConnectionResourceDisable
  )
  let client = useClient()

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let connection_resource_id = useDataValue({
    context: 'connection_resource',
    path: 'id',
    viewPath: props.viewPath,
  })
  let connection_title = useDataValue({
    context: 'connection_definition',
    path: 'title',
    viewPath: props.viewPath,
  })

  let connection_level = useDataValue({
    viewPath: props.viewPath,
    context: 'connection_resource',
    path: 'type',
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'complete_setup': {
        if (
          !window.confirm(
            'Are you sure you want to enable the integration for your current location? This may add automations required for the integration to function properly.'
          )
        )
          return

        let mutationResponse =
          await executeAppsConnectionResourceEnableMutation({
            id: connection_resource_id,
          })

        if (
          mutationResponse.error ||
          !mutationResponse.data.apps_connection_resource_enable.ok
        ) {
          return notify(
            notifyError(
              'An error occurred trying to enable the connection. Please refresh the page and try again.'
            )
          )
        } else {
          notify(notifySuccess(`Connection to ${connection_title} is enabled.`))
        }

        let { data } = await client
          .query(query, {
            location_id,
            app_id: value.app_id,
            connection_level: connection_level,
          })
          .toPromise()

        if (
          data.apps_automations.automations.some(
            automation => !automation.is_created
          )
        ) {
          // complete setup if there are any automations to be created
          setFlowTo(normalizePath(props.viewPath, 'SetupAutomations/Content'))
        }
        return
      }
      case 'create_automations': {
        setFlowTo(normalizePath(props.viewPath, 'SetupAutomations/Content'))
        return
      }
      case 'disable_connection': {
        if (
          !window.confirm(
            'Are you sure you want to disable the integration for your current location? This will also deactivate any automations added by the integration. You can re-enable it at any time.'
          )
        )
          return

        let mutationResponse =
          await executeAppsConnectionResourceDisableMutation({
            id: connection_resource_id,
          })
        if (mutationResponse.error) {
          return notify(
            notifyError(
              'An error occurred trying to disable the connection. Please refresh the page and try again.'
            )
          )
        } else {
          notify(
            notifySuccess(`Connection to ${connection_title} is disabled.`)
          )
        }

        return
      }

      default: {
        return
      }
    }
  }
}
