import View from './view.js'
import { useMemo } from 'react'

export default function Logic(props) {
  let overdueFlags = useMemo(
    () => ({
      has_insurance_overdues: props.overdues.some(
        overdue =>
          overdue.payment_account.account_type === 'InsurancePaymentAccount'
      ),
      has_personal_overdues: props.overdues.some(
        overdue =>
          overdue.payment_account.account_type === 'PersonPaymentAccount'
      ),
    }),
    [props.overdues]
  )

  return <View {...props} {...overdueFlags} />
}
