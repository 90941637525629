import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let value = useDataValue({
    context: 'steps',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (value.current_index === null || !value.steps?.length) {
      return null
    }

    let step = value.steps[value.current_index]
    if (!step) return null

    return {
      ...step,
      required:
        step.step_type === 'automation' && step.required
          ? {
              ...step.required,
              data: step.required?.data.length
                ? step.required?.data.map((item, index) => ({
                    ...item,
                    id: index,
                    value: null,
                  }))
                : null,
            }
          : null,
    }
  }, [value])
}
