import { DEFAULT_TIMEZONE } from 'Data/constants.js'
import { startOfDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let location = useDataValue({
    context: 'global',
    path: 'current_location',
    viewPath: props.viewPath,
  })
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    let time_zone_id = location.time_zone_id || DEFAULT_TIMEZONE
    let date = startOfDay(utcToZonedTime(new Date(), time_zone_id))
    return {
      ...data,
      selected: {
        ...data.selected,
        parent_company_id,
        location_id: location._id,
        vaxiom_location_id: location.id,
        location_name: location.name,
        time_zone_id,
        date,
        // static value, do not update this value from outside
        location_current_date: startOfDay(
          utcToZonedTime(new Date(), time_zone_id)
        ),
      },
      sidebar: {
        ...data.sidebar,
        date,
      },
    }
  }, [data, location, parent_company_id])
}
