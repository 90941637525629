import { getRemainingAmount } from 'Data/payment-plan.js'

export function getText(payment_plan) {
  if (payment_plan.insurances.some(insurance => !insurance.insured_id)) {
    return 'Please select an insurance subscription'
  } else if (payment_plan.payors.some(payor => !payor.person_id)) {
    return 'Please select a payor'
  } else if (getRemainingAmount(payment_plan) > 0) {
    return 'Remaining amount greater than zero'
  } else {
    return 'Please wait...'
  }
}
