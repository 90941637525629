import React, { useEffect } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let submitTab = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let appointment_type_id = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_type_id',
    viewPath: props.viewPath,
  })
  let appointment_id = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_id',
    viewPath: props.viewPath,
  })
  let date = useDataValue({
    context: 'tab',
    path: 'selected.date',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    submitTab({ type: 'scheduling/reset', refreshBlueBar: false })
  }, [appointment_type_id, date, appointment_id])

  return <View {...props} />
}
