import { birthday, name } from 'Data/validate.js'
import { useMutation } from 'Data/Api.js'
import { minutesToThreeDigitsTime } from 'Data/format.js'
import { useDataSubmit, useDataValue, useDataChange } from 'Simple/Data'
import { isNil, omitBy } from 'lodash'
import { toDate, utcToZonedTime } from 'date-fns-tz'
import { addDays, format, isBefore, subDays } from 'date-fns'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutationScheduleNewPatient from './mutation-schedule-new-patient.graphql.js'
import mutationScheduleExistingPatientAndNewAppointment from './mutation-schedule-existing-patient-and-new-appointment.graphql.js'
import mutationScheduleExistingPatientAndExistingAppointment from './mutation-schedule-existing-patient-and-existing-appointment.graphql.js'
import mutationCreateTreatment from './mutation-create-treatment.graphql.js'

// see useDataTransform
function toActualOriginalValue(originalValue) {
  return {
    ...originalValue,
    patient_id: null,
    patient_option: 'new',
    is_external_patient: false,
    appointment_id: null,
    is_external_appointment_id: false,
    treatment_id: null,
    is_external_treatment_id: false,
    is_external_any: false,
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionSchedule = useDataOnActionSchedule(props)
  let onActionUpdatePatientOption = useDataOnActionUpdatePatientOption(props)
  let onActionUpdatePatient = useDataOnActionUpdatePatient(props)
  let onActionUnsetPatientOption = useDataOnActionUnsetPatient(props)
  let onActionSetNewAppointment = useDataOnActionSetNewAppointment(props)
  let onActionSetExistingAppointment =
    useDataOnActionSetExistingAppointment(props)
  let onActionCreateTreatment = useDataOnActionCreateTreatment(props)

  return async function onSubmit(params) {
    let { args } = params

    switch (args?.type) {
      case 'schedule': {
        return onActionSchedule(params)
      }
      case 'updatePatientOption': {
        return onActionUpdatePatientOption(params)
      }
      case 'updatePatient': {
        return onActionUpdatePatient(params)
      }
      case 'unsetPatient': {
        return onActionUnsetPatientOption(params)
      }
      case 'setNewAppointment': {
        return onActionSetNewAppointment(params)
      }
      case 'setExistingAppointment': {
        return onActionSetExistingAppointment(params)
      }
      case 'createTreatment': {
        return onActionCreateTreatment(params)
      }
      default: {
        // throw new Error(`Unsupported action ${args?.type}`)
        console.error(`Unsupported action ${args?.type}`)
      }
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionSetNewAppointment(props) {
  let changeTab = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
  })
  let blue_bar_range = useDataValue({
    context: 'settings',
    viewPath: props.viewPath,
    path: 'blue_bar_range',
  })
  let timeZoneId = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.time_zone_id',
  })

  return async function onAction({ args, change }) {
    change(next => {
      next.appointment_id = null
      next.is_external_appointment_id = false
    })

    if (args.approximateDate) {
      changeTab(next => {
        next.selected.date = args.approximateDate
        next.blue_bar_days = calculateBlueBarDays(
          args.approximateDate,
          blue_bar_range,
          timeZoneId
        )
      })
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionSetExistingAppointment(props) {
  let changeTab = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
  })
  let timeZoneId = useDataValue({
    context: 'tab',
    path: 'selected.time_zone_id',
    viewPath: props.viewPath,
  })
  let blue_bar_range = useDataValue({
    context: 'settings',
    viewPath: props.viewPath,
    path: 'blue_bar_range',
  })

  return async function onAction({ value, args, originalValue, change }) {
    let { patient_id } = value

    if (args.approximateDate) {
      changeTab(next => {
        next.selected.date = args.approximateDate
        next.blue_bar_days = calculateBlueBarDays(
          args.approximateDate,
          blue_bar_range,
          timeZoneId
        )
      })

      if (value.is_external_any) {
        return
      }
    } else if (args?.preselect_scheduling_slot_config?.date) {
      changeTab(next => {
        next.selected.date = toDate(
          args.preselect_scheduling_slot_config.date,
          {
            timeZone: timeZoneId,
          }
        )
      })
    }
    await new Promise(resolve => {
      setTimeout(resolve, 1)
    })
    let actualOriginalValue = toActualOriginalValue(originalValue)
    change({
      ...actualOriginalValue,
      patient_id: args.patient_id || patient_id,
      patient_option: 'existing',
      appointment_id: args.id,
      appointment_type_id: args.type_id,
      treatment_id: args.treatment_id,
      duration: args.duration,
      notes: args.notes,
      ...(args.is_external
        ? {
            is_external_any: true,
            is_external_patient_id: true,
            is_external_appointment_id: true,
            is_external_treatment_id: true,
          }
        : {}),
      ...(args.preselect_scheduling_slot_config
        ? {
            preselect_scheduling_slot_config:
              args.preselect_scheduling_slot_config,
          }
        : {}),
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionUpdatePatient(props) {
  return async function onAction({ originalValue, change, args }) {
    change({
      ...toActualOriginalValue(originalValue),
      patient_id: args.id,
      patient_option: 'existing',
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionUnsetPatient(props) {
  let changeTab = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return async function onAction({ originalValue, change }) {
    change({
      ...toActualOriginalValue(originalValue),
      patient_option: 'existing',
    })
    changeTab(next => {
      next.blue_bar_days = {}
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionUpdatePatientOption(props) {
  let changeTab = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return async function onAction({ args, originalValue, change }) {
    change({
      ...toActualOriginalValue(originalValue),
      patient_option: args.patient_option,
    })
    changeTab(next => {
      next.blue_bar_days = {}
    })
  }
}

/**
 * @param {*} input
 * @returns {string | null}
 */
function validateNewPatient(input) {
  let { first_name, last_name, gender, birth_date } = input.person

  if (!name(first_name)) return 'Invalid first name'
  if (!name(last_name)) return 'Invalid last name'
  if (!gender) return 'Invalid gender'
  if (!birthday(birth_date)) return 'Invalid birth date'

  return null
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionSchedule(props) {
  let [, executeMutationScheduleNewPatient] = useMutation(
    mutationScheduleNewPatient
  )
  let [, executeMutationScheduleExistingPatientAndNewAppointment] = useMutation(
    mutationScheduleExistingPatientAndNewAppointment
  )
  let [, executeMutationScheduleExistingPatientAndExistingAppointment] =
    useMutation(mutationScheduleExistingPatientAndExistingAppointment)
  let [, notify] = useNotifications()
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })
  let slots = useDataValue({
    context: 'tab',
    path: 'scheduling.slots',
    viewPath: props.viewPath,
  })
  let slot_id = useDataValue({
    context: 'tab',
    path: 'scheduling.slot_id',
    viewPath: props.viewPath,
  })
  let untemplated_slot = useDataValue({
    context: 'tab',
    path: 'scheduling.untemplated_slot',
    viewPath: props.viewPath,
  })
  let submitOverlay = useDataSubmit({
    viewPath: props.viewPath,
    context: 'overlay',
  })

  return async function onAction({ value }) {
    let slot = slots.find(v => v.id === slot_id)
    let date = slot?.date || untemplated_slot?.date
    let start_time =
      slot?.start_time ||
      (typeof untemplated_slot?.start_min === 'number'
        ? minutesToThreeDigitsTime(untemplated_slot.start_min)
        : null)
    let end_time =
      slot?.end_time ||
      (typeof untemplated_slot?.end_min === 'number'
        ? minutesToThreeDigitsTime(untemplated_slot.end_min)
        : null)
    let chair_id = slot?.chair_id || untemplated_slot?.chair_id

    if (!date) {
      notify(notifyError('Date not selected'))
      return true
    }

    if (!start_time) {
      notify(notifyError('Start time not selected'))
      return true
    }

    if (!end_time) {
      notify(notifyError('End time not selected'))
      return true
    }

    if (!chair_id) {
      notify(notifyError('Chair not selected'))
      return true
    }

    let {
      emergency,
      patient_id,
      appointment_id,
      treatment_id,
      appointment_type_id,
      note,
      add_notes,
    } = value

    let shared = {
      location_id,
      ...(add_notes ? { note } : {}),
      date,
      chair_id,
      start_time,
      end_time,
    }

    let mutationResponse

    if (patient_id) {
      if (appointment_id) {
        mutationResponse =
          await executeMutationScheduleExistingPatientAndExistingAppointment({
            ...shared,
            patient_id,
            appointment_id,
          })
      } else {
        mutationResponse =
          await executeMutationScheduleExistingPatientAndNewAppointment({
            ...shared,
            patient_id,
            appointment_type_id,
            treatment_id,
            unplanned: emergency,
          })
      }
    } else {
      let validationResult = validateNewPatient(value.patient_new)

      if (validationResult) {
        notify(notifyError(validationResult))
        return true
      }

      let patient_form = omitBy(
        {
          ...value.patient_new.person,
          // prettier-ignore
          ...(typeof value.patient_new?.provider_employee_resource_id === 'number'
            ? {
                provider_employee_resource_id:
                  value.patient_new.provider_employee_resource_id,
              }
            : {}),
        },
        isNil
      )

      mutationResponse = await executeMutationScheduleNewPatient({
        ...shared,
        unplanned: false,
        appointment_type_id,
        patient_form,
      })
    }

    if (mutationResponse.error) {
      // TODO:
      notify(notifyError('Something went wrong'))
    } else {
      notify(
        notifySuccess(
          'Appointment for this patient is scheduled and added to calendar.',
          'Appointment scheduled!'
        )
      )
      submitOverlay()
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionCreateTreatment(props) {
  let [, executeMutation] = useMutation(mutationCreateTreatment)
  let [, notify] = useNotifications()
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, change, originalValue }) {
    let { patient_id } = value

    let mutationResponse = await executeMutation({
      patient_id,
      location_id,
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong'))
    } else {
      notify(notifySuccess('Treatment created'))
      change({
        ...toActualOriginalValue(originalValue),
        patient_option: 'existing',
        patient_id,
        treatment_id: mutationResponse.data.treatments_create_treatment._id,
      })
    }
  }
}

/**
 *
 * @param {Date} optimalDate
 * @param {{ before?: number, after?: number }} blueBarRange
 * @param {string} timeZoneId
 * @returns {Record<string, boolean>}
 */
function calculateBlueBarDays(optimalDate, blueBarRange, timeZoneId) {
  let before = blueBarRange.before ?? 0
  let after = blueBarRange.after ?? 0

  let today = utcToZonedTime(new Date(), timeZoneId)
  /** @type {Record<string, boolean>} */
  let days = {}

  for (
    let i = 0, date = subDays(optimalDate, 1);
    i < before && isBefore(today, date);
    date = subDays(date, 1)
  ) {
    if (date.getDay() === 0 || date.getDay() === 6) continue
    days[format(date, 'yyyy-MM-dd')] = false
    i++
  }

  days[format(optimalDate, 'yyyy-MM-dd')] = true

  for (
    let i = 0, date = addDays(optimalDate, 1);
    i < after;
    date = addDays(date, 1)
  ) {
    if (date.getDay() === 0 || date.getDay() === 6) continue
    days[format(date, 'yyyy-MM-dd')] = false
    i++
  }

  return days
}
