import { useMemo } from 'react'
import { DEFAULT_TIMEZONE } from 'Data/constants.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    return {
      appointment_booking: {
        id: props.appointment_booking_id,
        state: props.appointment_booking_state,
        confirmation_status: props.appointment_booking_confirmation_status,
        start: props.appointment_booking_start,
        end: props.appointment_booking_end,
        location: {
          id: props.appointment_booking_location_id,
          time_zone_id: props.time_zone_id || DEFAULT_TIMEZONE,
        },
        provider: {
          id: props.appointment_booking_provider_id,
        },
        assistant: {
          id: props.appointment_booking_assistant_id,
        },
        chair: {
          id: props.appointment_booking_chair_id,
        },
      },
      appointment: {
        id: props.appointment_id,
        has_notes: props.appointment_has_notes,
      },
      patient: {
        id: props.patient_id,
      },
      is_patient_overlay_call: props.is_patient_overlay_call === true,
    }
  }, [
    props.appointment_booking_id,
    props.appointment_booking_state,
    props.appointment_booking_confirmation_status,
    props.appointment_booking_start,
    props.appointment_booking_end,
    props.appointment_booking_location_id,
    props.time_zone_id,
    props.appointment_booking_provider_id,
    props.appointment_booking_assistant_id,
    props.appointment_booking_chair_id,
    props.appointment_id,
    props.appointment_has_notes,
    props.patient_id,
    props.is_patient_overlay_call,
  ])
}
