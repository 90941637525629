import {
  useDataIsSubmitting,
  useDataFormat,
  useDataSubmit,
} from 'Simple/Data.js'
import { getRemainingAmount } from 'Data/payment-plan.js'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let isMissingInsurance = useDataFormat({
    context: 'payment_plan',
    path: 'insurances',
    // couldn't save if there is any insurance without a selected subscription
    format: insurances => insurances.some(insurance => !insurance.insured_id),
    viewPath: props.viewPath,
  })
  let isMultiPayors = useDataFormat({
    context: 'payment_plan',
    path: 'payors',
    format: payors => payors.length !== 1,
    viewPath: props.viewPath,
  })
  let isMissingPayor = useDataFormat({
    context: 'payment_plan',
    path: 'payors',
    // couldn't save if there is no payor selected
    format: payors => payors.some(payor => !payor.person_id),
    viewPath: props.viewPath,
  })
  let has_remaining_amount = useDataFormat({
    context: 'payment_plan',
    format: payment_plan => getRemainingAmount(payment_plan) > 0,
    viewPath: props.viewPath,
  })

  return (
    isSubmitting ||
    isMissingInsurance ||
    isMultiPayors ||
    isMissingPayor ||
    has_remaining_amount
  )
}

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onSubmit() {
    submit({ type: 'send-request' })
  }
}
