import addDays from 'date-fns/addDays/index.js'
import format from 'date-fns/format/index.js'
import differenceInDays from 'date-fns/differenceInDays/index.js'

import parseISO from 'date-fns/parseISO/index.js'

export function getDescription(value) {
  let expiresDate = addDays(
    parseISO(value.updated_at),
    value.connection.app.connection.expiresInDays
  )

  return `Your connection to ${
    value.connection.app.name
  } will reset in ${differenceInDays(expiresDate, new Date())} days on ${format(
    expiresDate,
    'MMM d, yyyy, h:mm aaa'
  )}.`
}
