import {
  ensureDefaultTabId,
  isAllowedToAccessTab,
  ensureCommsAndCalendarTabs,
  filterAllowedTabsOnly,
  getSyncParams,
} from './helpers.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'tabs/add': {
        change(next => {
          let { focus = true, tab } = args

          // do this only when we have permissions, the session/set action
          // will take care of any other bits
          if (
            next.current_location &&
            !isAllowedToAccessTab({
              tab,
              current_location: next.current_location,
              feature_flags: next.feature_flags,
            })
          ) {
            ensureDefaultTabId(next)
            return
          }

          let tabIndex = next.tabs.findIndex(item => item.id === tab.id)
          if (tabIndex >= 0) {
            let sync = getSyncParams(tab)
            if (sync) {
              next.tabs[tabIndex].sync = sync
            } else {
              // TODO: consider removing this line if every section is responsible for updating the current tab internally
              // see /App/Account/Content/Patients/logic.js
              next.tabs[tabIndex] = tab
            }
          } else {
            next.tabs.push(tab)
          }
          // prevent refreshing the section when it should only sync the tab data
          if (focus && !tab.coreParams?.sync_history) {
            next.next_tab_id = tab.id
          }
        })
        return
      }

      case 'tabs/remove': {
        change(next => {
          let { tab } = args
          let tabIndex = next.tabs.findIndex(item => item.id === tab.id)
          next.tabs.splice(tabIndex, 1)
          // only change current tab if the tab you're closing is the active one
          if (tab.id === next.tab_id) {
            next.next_tab_id = next.tabs[tabIndex - 1].id
          }
          // When the tab is closed I removed the notification acknowledgment for the user
          if (tab.patient_id) {
            next.acknowledged_notifications_by_patient =
              next.acknowledged_notifications_by_patient.filter(
                patient_id => patient_id !== tab.patient_id
              )
          }
        })
        return
      }

      case 'reset': {
        change(originalValue)
        return
      }

      case 'tabs/closeAll': {
        change(next => {
          next.tabs = []
          ensureCommsAndCalendarTabs(next)
          filterAllowedTabsOnly(next)
          ensureDefaultTabId(next)
        })
        return
      }

      case 'session/set': {
        change(next => {
          let { current_location, feature_flags, user } = args
          if (current_location._id === next.current_location?.id) return

          next.current_location = current_location
          next.feature_flags = feature_flags
          next.user = user

          let hasTabs = next.tabs.length > 0

          ensureCommsAndCalendarTabs(next)
          filterAllowedTabsOnly(next)

          if (hasTabs && !next.tabs.some(item => item.id === next.tab_id)) {
            ensureDefaultTabId(next)
          }
        })
        return
      }

      case 'tabs/select': {
        change(next => {
          let { tab } = args
          next.next_tab_id = tab.id
        })
        return
      }

      case 'tabs/ensureActive': {
        change(next => {
          if (next.next_tab_id === null) return
          next.tab_id = next.next_tab_id
          next.next_tab_id = null
        })
        return
      }

      case 'tabs/ensureDefault': {
        change(next => {
          if (
            next.tabs.length === 0 ||
            !next.tabs.some(item => item.id === next.tab_id)
          ) {
            ensureDefaultTabId(next)
          } else {
            next.next_tab_id = next.tab_id
            next.tab_id = null
          }
        })
        return
      }

      case 'patientOverlay/open': {
        change(next => {
          next.patient_notifications_patient_id = args.patient_id
          next.patient_overlay_patient_id = args.patient_id
          next.appointment_overlay = originalValue.appointment_overlay
        })
        return
      }

      case 'patientOverlay/close': {
        change(next => {
          next.patient_overlay_patient_id = null
        })
        return
      }

      case 'appointmentOverlay/open': {
        change(next => {
          next.patient_overlay_patient_id = null
          next.appointment_overlay.open = true
          next.appointment_overlay.is_patient_overlay_call =
            args.is_patient_overlay_call
          next.appointment_overlay.patient_id = args.patient_id
          next.appointment_overlay.appointment_id = args.appointment_id
          next.appointment_overlay.treatment_id = args.treatment_id
        })
        return
      }

      case 'appointmentOverlay/close': {
        change(next => {
          next.appointment_overlay = originalValue.appointment_overlay
        })
        return
      }

      case 'patientNotifications/open': {
        change(next => {
          next.patient_notifications_patient_id = args.patient_id
        })
        return
      }

      case 'patientNotifications/close': {
        change(next => {
          next.patient_notifications_patient_id = null
        })
        return
      }

      default: {
      }
    }
  }
}
