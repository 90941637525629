import { useDataSubmit } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'selected',
  })

  return async function onSubmit({ value }) {
    submit({
      type: 'accept-treatment',
      is_financials_managed_externally: value.selected,
    })
  }
}
