import React from 'react'
import View from './view.js'
import CalendarDay from './CalendarDay/index'
import CalendarHeader from './CalendarHeader/index'
import { DataProvider, useDataValue } from 'Simple/Data.js'
import { format } from 'Data/date.js'

export default function Logic({ viewPath }) {
  let monthly_calendar_data = useDataValue({
    context: 'monthly_calendar_data',
    viewPath,
  })

  let selected_date = useDataValue({
    context: 'tab',
    viewPath,
    path: 'selected.date',
  })

  let blueBarDays = useDataValue({
    context: 'tab',
    viewPath,
    path: 'blue_bar_days',
  })

  function renderDay(date) {
    let formattedDate = format(date, 'yyyy-MM-dd')
    let day_data =
      monthly_calendar_data?.find(data => data?.date === formattedDate) ?? {}

    let blueBarConfig = {}
    if (blueBarDays && formattedDate in blueBarDays) {
      let isOptimal = blueBarDays[formattedDate]
      blueBarConfig = isOptimal
        ? { isOptimal: true }
        : { isBeforeOrAfter: true }
    }

    return (
      <DataProvider
        context="calendar_day"
        value={{
          ...day_data,
          ...blueBarConfig,
          day: format(date, 'd'),
          date,
          is_selected: format(selected_date, 'yyyy-MM-dd') === formattedDate,
        }}
        viewPath={`${viewPath}/CalendarDay(${date.getTime()})`}
      >
        <CalendarDay viewPath={`${viewPath}/CalendarDay(${date.getTime()})`} />
      </DataProvider>
    )
  }

  function weekdayElement(props) {
    return (
      <DataProvider
        context="calendar_header"
        value={{
          date: props.localeUtils
            .formatWeekdayShort(props.weekday)
            .toUpperCase(),
        }}
        viewPath={`${viewPath}/CalendarHeader`}
      >
        <CalendarHeader viewPath={`${viewPath}/CalendarHeader`} />
      </DataProvider>
    )
  }

  return (
    <View
      viewPath={viewPath}
      renderDay={renderDay}
      weekdayElement={weekdayElement}
    />
  )
}
