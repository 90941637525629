import { useMutation } from 'Data/Api'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { notifyError, useNotifications } from 'Logic/Notifications'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let treatment_id = useDataValue({
    context: 'treatment',
    path: '_id',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'wizard',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()

  let [, executeAcceptTreatmentMutation] = useMutation(mutation)

  return async function onSubmit({ value, args }) {
    let mutationResponse = await executeAcceptTreatmentMutation({
      treatment_plan_id: value.treatment_plan_id,
      treatment_id,
      is_financials_managed_externally: !!args.is_financials_managed_externally,
      with_financial_externally:
        args.is_financials_managed_externally !== null &&
        args.is_financials_managed_externally !== undefined,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    submit({ type: 'done' })
  }
}
